import cat from './cat.jpeg';
import './App.css';
import ConfettiGenerator from 'confetti-js';
import React from 'react';
import Button from '@mui/material/Button';
import { FaBirthdayCake } from 'react-icons/fa';
import CountUp from 'react-countup';
import { useCountUp } from 'react-countup';




function App() {

  const [imageClass, setImageClass] = React.useState('App-logo');
  const [presentTextClass, setPresentTextClass] = React.useState('invisible');
  const [buttonClass, setButtonClass] = React.useState('');



  React.useEffect(() => {
    const confettiSettings = { target: 'my-canvas' };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
   
    return () => confetti.clear();
  }, [])

  const countUpRef = React.useRef(null);
  const { start, pauseResume, reset, update } = useCountUp({
    ref: countUpRef,
    start: 100,
    end: 1028,
    prefix: '$',
    delay: 1,
    duration: 5,
    onPauseResume: () => console.log('Paused or resumed!'),
    onStart: ({ pauseResume }) => console.log("HDSFPSFJSPODOJ"),
    onEnd: ({ pauseResume }) => console.log(pauseResume),
  });

  return (
    <div className="App">
      <canvas id="my-canvas" className="theCanvas"></canvas>
      <header className="App-header">
        <img src={cat} className={imageClass} alt="logo" />
        <h1 className={presentTextClass} style={{position: 'absolute'}}>Voucher for <span className="linky" ref={countUpRef}/> to spend <a href="https://infresh.by" target="_blank">here</a></h1>
        <Button className={buttonClass} size="large" color="secondary" variant="outlined" startIcon={<FaBirthdayCake />} onClick={() => {
          setImageClass('App-logo hide');
          setPresentTextClass('visible');
          setButtonClass('hide')
          start();
          }}>
          Get Your Present
        </Button>
      </header>
    </div>
  );
}

export default App;
